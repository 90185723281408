<template lang="">
  <div class="px-50">
    <BTable
      class="m-0 bg-white rounded-8px"
      :items="priceList.fareOpts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('noMatchingResult')"
      no-border-collapse
      small
    >
      <template
        v-for="column in tableColumns"
        #[`head(${column})`]=""
      >
        <div
          :key="column"
          class="text-dark text-nowrap"
          :class="`${['netFare', 'taxFare', 'totalFare'].includes(column) ? 'text-right' : 'text-center'}`"
        >
          {{ $t(`flight.combinationTab.table.${column}`) }}
        </div>
      </template>

      <template #cell(paxType)="{ item }">
        <div class="text-center">
          {{ $t(`flight.${item.paxType}`) }}
        </div>
      </template>

      <template #cell(amount)="{ item }">
        <div class="text-center">
          {{ item.amount }}
        </div>
      </template>

      <template #cell(netFare)="{ item }">
        <div class="text-right">
          <!-- {{ formatCurrency(item.priceFareCol) }} -->
          <span
            v-b-tooltip.hover.html.v-primary.window
            class="text-nowrap position-relative"
            :title="`Giá vé: ${formatCurrency(item.fare)}<br> Chiết khấu: ${formatCurrency(item.promotion)}<br> ${source === '1G' ? 'Chiết khấu hãng: Vui lòng xem bảng chiết khấu BSP - Hoặc liên hệ booker, sales' : `Khuyến mại hãng: ${formatCurrency(item.discount)}`}`"
          >
            {{ formatCurrency(item.priceFareCol) }}
            <span
              v-if="item.priceDiscountFareCol > 0"
              class="badge badge-up badge-pill bg-danger badge-glow text-nowrap discount-badge"
            >
              {{ `${numberToFixed((item.priceDiscountFareCol / item.fare) * 100)}%` }}
            </span>
          </span>
          <div>
            <del
              v-if="(item.discount || item.promotion) && getShowPriceAfterDiscount"
              class="text-secondary"
              :class="isMobileView ? 'font-small-2' : 'font-small-4'"
            >
              {{ formatCurrency(item.fare) }}
            </del>
          </div>
        </div>
      </template>

      <template #cell(taxFare)="{ item }">
        <div class="text-right">
          <span
            v-b-tooltip.hover.html.v-primary.window
            class="text-nowrap"
            :title="`Thuế và phí: ${formatCurrency(item.tax + item.surcharge + (!getShowServiceFee && getShowPrice !== 'NET_FARE' ? item.serviceFee : 0))} ${getShowServiceFee ? `<br>Phí dịch vụ: ${formatCurrency(item.serviceFee)}` : ''} ${getShowAgencyFee && item.title !== 'INF' ? `<br>Phí xuất vé: ${formatCurrency(item.agencyFee)}` : ''}`"
          >
            {{ formatCurrency(item.priceFeeCol) }}
          </span>
        </div>
      </template>

      <template #cell(totalFare)="{ item }">
        <div class="text-danger text-right fw-700">
          <span :class="`text-nowrap ${item.discount ? 'text-success' : ''}`">
            {{ formatCurrency(item.priceTotalCol) }}
          </span>
          <div>
            <del
              v-if="(item.discount || item.promotion) && getShowPriceAfterDiscount"
              class="text-secondary"
              :class="isMobileView ? 'font-small-2' : ''"
            >
              {{ formatCurrency(item.priceTotalCol + item.discount + item.promotion) }}
            </del>
          </div>
        </div>
      </template>
    </BTable>
    <div
      v-if="isVisiblePriceFareCol && priceList.total"
      class="text-danger d-flex-center justify-content-end gap-1 my-50"
    >
      <span class="font-weight-bolder">Tổng tiền:</span>
      <span class="fw-800 font-medium-5 ">{{ formatCurrency(priceList.total) }}</span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { BTable, VBTooltip } from 'bootstrap-vue'

import store from '@/store'
import { numberToFixed } from '@/views/invoices/useInvoiceHandle'

import {
  formatCurrency,
} from '@core/utils/filter'

export default {
  components: {
    BTable,
  },
  directives: { 'b-tooltip': VBTooltip },
  props: {
    priceList: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  setup() {
    const tableColumnsRaw = ref(['paxType', 'amount', 'netFare', 'taxFare', 'totalFare'])

    const getShowPriceAfterDiscount = computed(() => store.getters['app-flight-v2/getShowPriceAfterDiscount'])

    const getShowPrice = computed(() => store.getters['app-flight-v2/getShowPrice'])
    const getShowServiceFee = computed(() => store.getters['app-flight-v2/getShowServiceFee'])
    const getShowAgencyFee = computed(() => store.getters['app-flight-v2/getShowAgencyFee'])

    // Visible các cột
    const isVisiblePriceFareCol = computed(() => getShowPrice.value !== 'HIDE_FARE')
    const isVisibleFeeCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))
    const isVisibleTotalFareCol = computed(() => !['HIDE_FARE', 'TICKET_FARE'].includes(getShowPrice.value))

    const tableColumns = computed(() => {
      let table = tableColumnsRaw.value
      if (!isVisiblePriceFareCol.value) table = table.filter(t => t !== 'netFare')
      if (!isVisibleFeeCol.value) table = table.filter(t => t !== 'taxFare')
      if (!isVisibleTotalFareCol.value) table = table.filter(t => t !== 'totalFare')
      return table
    })
    return {
      tableColumns,
      formatCurrency,
      getShowPrice,
      getShowServiceFee,
      getShowAgencyFee,
      getShowPriceAfterDiscount,
      isVisiblePriceFareCol,
      numberToFixed,
    }
  },
}
</script>
<style lang="">

</style>
